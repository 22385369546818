.conten-Details{
  background-image: url('../../../res/images/background-details.png');
  background-color: #D9D9D9;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  max-width: 500px;
}
.Dialog-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0px;
}
.dialog-conten-text{
  margin-left: 30px;
  margin-right: 30px;
  width: 420px;
  font-weight: 300;
  text-align: left;
  color: black;
}
.text-end{
  margin: 30px;
  margin-top: 30px;
  font-weight: 270;
  text-align: left;
  color: black;
}
.close-x {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  font-size: 30px !important;
  color: black;
}
.close-x:hover{
  color: #ffa80a;
}
.conten-button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
}
@media (max-width: 1000px) {
  .links {
    font-size: 50%;
  }
}
@media (max-width: 910px) {
  .links {
    font-size: 30%;
  }
}
@media (max-width: 870px) {
  .links {
    font-size: 0%;
  }
}