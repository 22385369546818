.nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0px;
}

.nav-options {
    margin-right: 20px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    padding: 10px;
    column-gap: 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #535353;
    margin-right: 300px;
}

.toolbar-buttons {      
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
    grid-column-gap: 10px;
    column-gap: 30px;
    align-content: center;
    align-items: center;
}

.toolbar-login {
    width: 117px;
    height: 24px;
    background: #000000;
    border-radius: 50px;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
}

.toobar-singup {
    width: 117px;
    height: 24px;
    background: #FFAE39;
    border-radius: 50px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    
}