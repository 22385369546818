.conten-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.background-info{
  background-image: url('../../../res/images/background-details.png');
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;
  background-size: 45%;
}


.conten-dialog-title {
  font-display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 30px;
  padding-top: 30px;
  padding-left: 8%;

}

.conten-dialog-title h2{

  font-weight: 900;
  font-size: 30pt;
  font-family: Arial;
  -webkit-text-stroke: 2px #FFA500;
  color: transparent; 
  text-align: left;
}

.conten-dialog-text{
  text-align: left;
  color: #1F1F1F;
  font-size: 12pt;
  width: 700px;
  padding: 5px;
  line-height: 1.4;
}

.button-home-ingresar {
  background: #E79200!important;
  color: white !important;
  min-width: 100px ;
  min-height: 30px;
  border-radius: 100px;
}
.button-ingresar {
  background: #FFA500!important;
  color: white !important;
  min-width: 200px ;
  min-height: 40px;
  border-radius: 100px;
}