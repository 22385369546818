:root {
    --mdc-typography-headline1-font-family: var(--titles-font);
    --mdc-typography-headline2-font-family: var(--titles-font);
    --mdc-typography-headline3-font-family: var(--titles-font);
    --mdc-typography-headline4-font-family: var(--titles-font);
    --mdc-typography-headline5-font-family: var(--titles-font);
    --mdc-typography-headline6-font-family: var(--titles-font);
    --mdc-typography-subtitle1-font-family: var(--titles-font);
    --mdc-typography-subtitle2-font-family: var(--titles-font);
    --mdc-typography-button-font-family: var(--titles-font);
    --mdc-typography-font-family: var(--default-font);
    --mdc-theme-error: #d43551;
    --toolbar-height: 72px;
    --content-top-padding: .8rem;
}

.mdc-typography--headline1,
.mdc-typography--headline2,
.mdc-typography--headline3,
.mdc-typography--headline4,
.mdc-typography--headline5,
.mdc-typography--headline6,
.mdc-drawer__title,
.mdc-top-app-bar__title,
.mdc-data-table__header-cell,
.mdc-dialog__title {
    font-family: var(--titles-font);
    font-weight: 700;
}

.mdc-drawer {
    top: 0;
}

.mdc-typography--subtitle1,
.mdc-typography--subtitle2,
.mdc-list-item__primary-text {
    font-family: var(--titles-font);
    font-weight: 500;
}

.mdc-typography--button,
.mdc-button,
.mdc-fab__label,
.mdc-tab {
    font-family: var(--titles-font);
    font-weight: 700;
    letter-spacing: 0.05715em;
}

.mdc-top-app-bar__row {
    height: var(--toolbar-height);
}

.mdc-top-app-bar--fixed-adjust {
    padding-top: calc(var(--toolbar-height) + var(--content-top-padding));
}

.mdc-top-app-bar__title {
    font-weight: 600;
    padding-left: 8px;
    margin-top: .2rem;
}

.mdc-top-app-bar,
.mdc-top-app-bar--fixed {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

.mdc-top-app-bar a {
    text-decoration: none !important;
    color: var(--mdc-theme-on-primary);
}

.mdc-body {
    min-height: calc(100vh - var(--toolbar-height) - calc(var(--content-top-padding, .8rem) * 3));
    padding: .8rem 1.6rem;
}

.mdc-snackbar {
    bottom: 1.2rem;
}

.mdc-snackbar--leading {
    left: 1.2rem;
}

.mdc-drawer__header {
    min-height: var(--toolbar-height);
    justify-content: center;
    align-items: center;
    display: flex;
}

.mdc-divider {
    height: 1px;
    background-color: rgba(0, 0, 0, .12);
}

.mdc-list-item__graphic.mdi {
    margin-top: .2rem;
    margin-right: 24px;
}

a.toolbar-logo {
    display: inline-block;
    line-height: 1;
}

a.toolbar-logo img {
    display: block;
    height: calc(var(--toolbar-height) - 1.6rem);
    width: auto;
    max-width: 100%;
    margin-left: .8rem;
    padding-top: .4rem;
    padding-bottom: .4rem;
}

.mdc-text-field__input {
    font-family: var(--default-font);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
body .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label {
    color: var(--mdc-theme-error, #b00020);
}

.mdc-fab .mdc-fab__label {
    font-size: 1rem;
}

.mdc-fab--extended .mdc-fab__icon {
    margin-left: -4px;
    margin-right: 8px;
}

.mdc-fab.positioned {
    position: fixed;
    bottom: 1.6rem;
    right: 1.6rem;
}

.mdc-drawer__content a {
    text-decoration: none;
}

button.mdc-button .mdc-button__label {
    margin-top: .2rem;
}

.mdc-text-field {
    width: 100%;
}

.button-full {
    width: 100%;
    height: 45px;
}

.button-medium {
    width: 50%;
    height: 45px;
}

.button-action {
    margin: 10px;
    height: 45px;
}

.profile {
    border-radius: 50%;
    width: 85% !important;
    height: 90% !important;
}

.full {
    width: 100%;
    height: 100%;
}

.profile-tool {
    padding: 0px;
    padding-top: 5px;
}

.mdc-list-item__graphic.mdi {
    font-size: 24px;
}

.tab-content {
    display: inline-flex;
}

.tab-text {
    padding: 15px;
}

.form-control {
    padding: 16px;
}

.fab-config {
    width: 40px;
    height: 40px;
    background-color: #1977ff;
}

.mdc-fab {
    color: white;
}
