header#menu-header{
    position: fixed;
    z-index: 10000;
    width: 100%;
    background-color: #ffffff;
    top: 0;
}
.container-menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 2em;

}
.container-menu figure{
    width: 10em;
    overflow: hidden;
    height: 50px;
    margin: 0;
}
.container-menu figure img{
    object-fit: contain;
    width: 100%;    
    height: 100%;
}
.span-icon{
    color: black;
    display: flex;
    gap: 1em;
    align-items: center;
    padding: 15px;
    cursor: pointer;
}
header#menu-header ul{
    display: block;
    position: fixed;
    width: 250px;
    max-width: 230px;
    list-style: none;
    height:100vh;
    padding: 2em;
    background-color: #ffffff;
    z-index: 1000;
    margin: 0;
    overflow: hidden;
}

.menu-statustrue{
    left: 0;
    transition: all .5s;

}
.menu-statusfalse{
    left: -100%;
    transition: left .5s ease-in-out;    
}
.menu-statustrue.overlay{
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1;
    transition: display .2s ease-in-out;
}
.menu-statusfalse.overlay{
   display: none;
}
header#menu-header ul.menu-statustrue li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    cursor: pointer;
}
.link-href{
    text-decoration: none;
}
header#menu-header ul.menu-statustrue li:active, header#menu-header ul.menu-statustrue li:hover {
    background-color: #e7920073 ;
    color: #000000;
    border-radius: 10px;

}