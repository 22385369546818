.send-enterprice {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #D9D9D9;
    font-family: 'Inter';
    font-style: normal;    
    font-size: 16px;   
    color: #000000;
}
.mdc-snackbar__label, .mdc-snackbar__surface{
    background-color: #E79200;
}
.sendLink-disable:hover{
    cursor: not-allowed !important;
  }

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background{
    background-color: #E79200 !important;
    border-color: #E79200 !important;
}
