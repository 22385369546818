@font-face {
  font-family: Inter;
  src: url('./../../res/fonts/Inter.ttf');
}

.cardImg {
  position: relative;
  top: -30px;
  right: 20px;
  height: 120px;
}

.cardImg img {
  background-color: transparent;
  width: 140px;
}

.cardCourse {
  position: relative;
  border-radius: 10px;
  width: 420px;
  margin: 10px;
  padding: 5px 25px;
  height: 252px;
  margin-bottom: 30px;
  align-self: center;
  justify-self: center;
}

.cardCourse-blue {
  background: #6AC9FF;
}

.cardCourse-honey {
  background: #FFAE39;
}

.cardCourse-salmon {
  background: #FF6939;
}

.__cardCourseCart {
  position: absolute;
  display: inline-flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: 20px;
  background: rgba(255, 255, 255, 0.5);
}

.__cardCourseCart img {
  background: transparent;
  width: 18px;
  margin: auto;
}

ul {
  list-style: none;
  padding-left: 0;
}

.cardInformation li {
  margin-bottom: 10px;
  color: #FFF;
  font-size: 18px;
  align-content: center;
  display: flex;
}

.cardInformation li img {
  width: 20px;
  float: left;
  margin-right: 25px;
  padding: 0;
  margin: 0 10px 0 0;
  background-color: transparent;
}

.callAction-courses {
  display: flex;
  justify-content: center;
  width: 180px;
  height: 40px;
  background: #FFF;
  border-radius: 50px;
  text-decoration: none;
  border: 2px solid #000 ;
}

.callAction-courses div {
  width: 100%;
  text-align: center;
  justify-content: center;
  margin: auto auto;
  font-size: 16px;
  color: #000;
  font-family: 'Inter';
  font-weight: 700;
}

.__callAction li {
  list-style: none;
}

.__callAction img {
  background-color: transparent;
  width: 20px;
  margin-left: 5px;
}

.mainText {
  position: relative;
  top: 20px;
  width: 100%
}

.mainText h3 {
  font-size: 25px;
}

.__cardCourseRow1 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
}

.__cardCourseRow2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.__courses-section {
  display: flex;
  flex-direction: row;
  padding: 20px 10px;
  grid-row: 2;
  grid-column: 2 / 3;
  box-sizing: border-box;
}

.courses-section {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr ;
  grid-template-rows: auto auto;
}

.courses-section h2 {
  font-family: 'Inter';
  grid-column: 2 / 3;
  grid-row: 1;
  padding: 20px;
  color: #535353;
  font-weight: 800;
}

li, h3 {
  font-family: 'Inter';
}

.courses-section-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(470px, 1fr));
  grid-template-rows: auto auto;
  width: 100%;
}

@media (max-width: 576px) {
  .cardCourse {
    width: 302px;
    height: 298px;
  }

  .courses-section-grid {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
  .callAction-courses {
    width: 140px;
    height: 40px;
  }
}
