@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap');
:root {
    --system-font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Inter",
    sans-serif;
    --manrope-font: 'Manrope', var(--system-font);
    --default-font: 'Inter', var(--system-font);
    --highlight-font: 'Inter', var(--system-font), bold;
}


.use-manrope {
    font-family: var(--manrope-font);
}

img:not([alt]) {
    background-color: #f00;
}

body {
    margin: 0;
    font-family: var(--default-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    background: #fafafa;
    font-size: 14px;
}

h1 {
    font-family: var(--mdc-typography-headline1-font-family, var(--manrope-font));
    font-size: 2.8rem;
    font-weight: 800;
}

h2 {
    font-family: var(--mdc-typography-headline2-font-family, var(--manrope-font));
    font-size: 2.4rem;
    font-weight: 700;
}

h3 {
    font-family: var(--mdc-typography-headline3-font-family, var(--manrope-font));
    font-size: 2.0rem;
    font-weight: 700;
}

h4 {
    font-family: var(--mdc-typography-headline4-font-family, var(--manrope-font));
    font-size: 1.6rem;
    font-weight: 600;
}

h5 {
    font-family: var(--mdc-typography-headline5-font-family, var(--manrope-font));
    font-size: 1.3rem;
}

h6 {
    font-family: var(--mdc-typography-headline6-font-family, var(--manrope-font));
}

b {
    font-weight: 600;
}

p {
    font-size: 1rem;
}
#render-toolbar{
    display: block;
}
#menu-header{
    display: none;
}
@media only screen and (max-width: 1023px) {
    #render-toolbar{
        display: none;
    }
    #menu-header{
        display: block;
    }
}

