@font-face {
  font-family: Inter;
  src: url('./../../res/fonts/Inter.ttf');
}

.carousel .control-dots .dot {
  opacity: 1;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: #FFAE39;
}

.main-label {
  position: absolute;
  max-width: 561px;
  left: 190px;
  top: 210px;
}

.main-label p {
  color: #FFFFFF;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 55px;
  font-weight: 700;
  text-align: left;
}

.main-label p span {
  color: #FFAE39;
}

.callAction {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 190px;
  top: 450px;
  width: 293px;
  height: 52px;
  background: #FFAE39;
  border-radius: 50px;
  text-decoration: none;
}

.callAction div {
  width: 100%;
  text-align: center;
  justify-content: center;
  margin: auto auto;
  font-size: 25px;
  color: #000;
  font-family: 'Inter';
  font-weight: 700;
}

.mainSlide {
  min-height: 750px;
  max-height: 90vh;
  object-fit: cover;
  object-position: 60%;
}

.hexaImg {
  position: absolute;
  bottom: 30px;
  right: 180px;
}

.hexaImg img {
  max-width: 650px;
}

@media (max-width: 960px) {

  .carousel {
    margin-top: 20px;
  }

  .main-label {
    left: 50%;
    top: 65px;
    width: 700px;
  }

  .main-label-bottom {
    top: 390px;
  }

  .__main-label {
    position: relative;
    left: -50%;
  }

  .main-label p {
    text-align: center;
    color: #FFF;
  }

  .main-label-bottom p {
    text-align: left;
    font-size: 36px;
  }

  .callAction {
    position: static;
    width: 250px;
  }

  .callAction div {
    color: #FFF;
  }


  .callAction_m {
    position: absolute;
    top: 620px;
    left: 50%;
  }

  .__callAction_m {
    position: relative;
    left: -50%;
  }

  .hexaImg {
    left: 50%;
    max-width: 500px;
    min-width: 340px;
    bottom: 50px;
  }

  .__hexaImg {
    position: relative;
    left: -50%;
  }

  .__hexaImg img {
    content:url('./../../res/images/mini.png');
  }

  .mainSlide {
    height: 100vh;
    width: auto;
  }
}

@media (max-width: 576px) {
  .main-label p {
    font-size: 35px;
  }

  .main-label {
    position: absolute;
    max-width: 355px;
  }

  .main-label-bottom p {
    text-align: left;
    font-size: 32px;
    padding-left: 5px;
  }
}