.card-suscribe {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    background-color: #000000;
}

.container-newsletter {
    display: flex;
    flex-direction: column;
    background-color: #000000;
    margin-top: 0px;
}

.card-suscribe h2 {
    margin-bottom: 0;
    font-size: 20px;
}

.card-suscribe p {
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 0;
}

.newsletter-form {
    display: flex;
    grid-gap: 1rem;
    padding: 3em;
    margin: auto;
    flex-wrap: wrap;
}

.emailbox, .emailbox:focus-visible{
    width: 470px;
    height: 47.5px;
    margin-left: auto;
    background-color: #000000;
    border: 2px solid #FFAE39;
    border-radius: 60px 0px 0px 60px;
    border-right: none;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-left: 19px;
    outline: none;
    color: #FFFFFF;
}


.container-suscribe {
    display: flex;
    padding-left: 10px;
}

.container-suscribe button {
    display: flex;
    background: #FFAE39 !important;
    border-radius: 50px;
    width: 225px;
    height: 55px;
    border: none;
    position: relative;
    right: 23px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    text-transform: lowercase;
}


.container-suscribe button span:first-letter {
    text-transform: uppercase;
}
.mdc-snackbar__label, .mdc-snackbar__surface{
    background-color: #E79200;
}


@media (max-width: 900px) {
    .newsletter-form {
        width: auto;
        justify-content: center;
    }

    .emailbox {
        border-radius: 60px;
        width: auto;
        margin-bottom: 10px;
        border-right: 60px;       
        border: 2px solid #FFAE39;
        padding-left: 14px;
    }
    .emailbox:focus-visible{
        border-radius: 60px;
        width: auto;
        margin-bottom: 10px;
        border-right: 60px;       
        border: 2px solid #FFAE39;
        padding-left: 14px;
    }

    .container-suscribe {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .container-suscribe button {
        position: static;
    }

}
@media screen and (min-width: 900px) and (max-width: 1439px) {
    .newsletter-form {
        display: flex;
        grid-gap: 1rem;
        padding: 3em;
        margin: auto;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
    }
    .container-suscribe {
        display: flex;
        padding-left: 10px;
        flex-direction: row;
        max-width: 60%;
    }

}