.footer {
  margin: 0;
}

.first-footer {
  background-color: #535353;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0;
  min-height: 170px;
  flex-wrap: wrap
}

.first-footer h4 {
  margin: 0;
  font-size: 18px;
  color: #FFF;
  margin-bottom: 20px;
}

.first-footer img {
  background-color: transparent;
}

.first-footer a {
  display: block;
  color: #FFF;
  text-decoration: none;
  margin: 5px 0;
}

.second-footer {
  background-color: #393939;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 15px 0;
  height: 106px;
}

.second-footer p {
  margin: 0;
  color: #FFF;
}

.second-footer img {
  background-color: transparent;
}

.third-footer {
  background-color: #E79200;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #FFF;
}

.redes-sociales img {
  margin: 0 5px;
}

@media (max-width: 960px) {
  .first-footer {
    flex-direction: column;
  }
}