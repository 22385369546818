.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-completed {

  color: #E79200; /* Cambiar el color step completed */
}
.container-disc-test{
    margin-top: 100px;
    background: linear-gradient(180deg, #FFFFFF 0%, #ffffff 100%);
    border-radius: 45px;
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 20px;
}

._question-disc{
  display: flex;
  margin-top: 10px;
  padding: 30px;
}
@media screen and (min-width: 1390px) {
  ._question-disc{
  margin-left: 3vw;
  }
}
.buttons-container{
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 10px;
}
.button-back-disc{
    margin: 18px;
    background: #2D2D2D;
    border-radius: 40px;
    width: 180px;
    height: 45px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
}
.button-next-disc{
    margin: 18px;
    background: #C8C8C8;
    border-radius: 40px;
    width: 180px;
    height: 45px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #B3B3B3;
}
.active {
    margin: 18px;
    background: #2D2D2D;
    border-radius: 40px;
    width: 206px;
    height: 53px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
  }
@media screen and (max-width: 767px) {
    .buttons-container{
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 20px;
      margin-top: 40px;
    }
  }