.container-dashboardDisc{
    margin-top: 50px;
}
.button-pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 190px;
    right: 80px;
    z-index: 1; 
    background: #FF6939 !important;
    border-radius: 100px; 
    color: #fff !important; 
}
.container-result{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 400px;
}
.container-routSuggest{
    display: flex;
    margin: 50px;
}
.container-louder{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 150px;
}