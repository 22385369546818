.container-candidates {
    margin-top: 50px;
    width: auto;
    display: grid;
    align-items: center;
    background-color: #FFFFFF;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    color: #000000;


}

.container-image-candidates,
.container-image-candidates img {
    width: 100%;
}

.container-background-none-text {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;    
    color: #000000;

}

.container-background-none-text h2 {
    width: 50%;
    text-align: left;
    justify-content: center;
}

.container-background-none-text img {
    width: 50%;
}

.button-see-programs-candidates {
    margin-top: 50px;
    width: 117px;
    height: 24px;
    background: #FFAE39;
    border-radius: 50px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: black;
    font-weight: 700;
}