.carddrag {
  background-color: #E79200;
  color: #FFFFFF;
  min-width: 165px;
  margin: 5px;
  padding: 5px;
  border-radius: 50px;
  font-weight: bold;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transform: translate(0, 0);
}
.carddrag:hover {
  cursor: move;
  /* fallback: no `url()` support or images disabled */
  cursor: -webkit-grab;
  /* Chrome 1-21, Safari 4+ */
  cursor: -moz-grab;
  /* Firefox 1.5-26 */
  cursor: grab;
}
.spot {
  margin: 5px;
  min-width: 175px;
  height: 50px;
  border-radius: 50px;
  border: 2px dotted #E79200;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-weight: bold;
}

.option-drop {
  background-color: #2D2D2D;
  display: inline-flex;
  flex-direction: column;
  margin: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 20px 5px 10px;
  color: #FFF;
  font-weight: bold;
  border-radius: 25px;
  min-width: 245px;
  min-height: 230px;
}

.option-drop img:last-child {
  margin-top: 10px;
}

.option-description {
  max-width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 0;
  margin-top: 10px;
  animation: aparecer 1.5s 0s;
}

.option-drop button {
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
}

.option-drop button:hover {
  cursor: pointer;
}

.math-card {
  padding: 0;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

@keyframes aparecer {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

}

.question-disc {
  display: flex;
  justify-content: center;
  align-items: center;
}

._question-disc {
  margin-top: 40px;
  display: inline-flex;
  flex-direction: column;
  background-color: #D9D9D9;
  border-radius: 25px;
  padding: 30px 30px 40px;
}

._question-disc p { 
  color: #E79200;
}
._question-disc #text-dragAndDrop{
  color: #2D2D2D;
}
