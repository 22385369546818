.conten{
  max-width: 410px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: normal !important;
}
.emoji {
  padding: 10px;
  width: 80px
}
.button-res {
  margin: 10px;
  background: #FFA500 !important;
  color: white !important;
  min-width: 270px ;
  min-height: 40px;
  text-shadow: 1px 1px 30px black;
}
.conten text{
  font-size: medium;
}