.container {
    background: #EFEFEF;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 150px auto 0;
    text-align: center;
    padding-bottom: 35px;
}

.title-section-graduates {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 45px;
    color: #535353;
}

.description-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #535353;
}

.card {
    background: #EFEFEF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card__title {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin: 0;
    color: #535353;
}

.text-title {
    margin: 0;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #535353;
}

.gallery {
    background: #EFEFEF;
    display: grid;
    grid-gap: 5rem;
    gap: 5rem;
    grid-auto-flow: dense;

    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    padding: 1em;
}

.featured {
    grid-column: 1 /-1;
}