.container-login{  
    background-color: #fff;         
}
.img-bxlogo{
    height: 40%;
}
.selector-login{

    width: 215px;
    height: 25px; 
    font-size: 14px;
}
.home-bx{
    position: static;
    width: 100%; 
    height: 100vh; 
    margin: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../res/images/BackgroundLogin.svg');   
    top:0;  
}

.captcha {
    margin-left: 10%;
    margin-right: 10%;
}

button.is-medium.is-info.is-fullwidth.show {
    display: flex;
}

button.is-medium.is-info.is-fullwidth.hide {
    display: none;
}

.container.has-text-centered.box.login {
    max-width: 400px;
}

.show {
    display: block;
}

.show-captcha {
    display: block;
}

.hide {
    display: none;
}

.sign {
    text-align: center;
}

.login-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-login {
    display: flex;
    flex-direction: row;
    justify-content: space-around;    
    font-weight: 400;
    line-height: 17px;
    color: #535353;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    
}

.register {
    display: flex;
    width: 100%;
}
.button-close-login{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    
}
.container-login::-webkit-scrollbar {
    width: 30px;     /* Tamaño del scroll en vertical */
    height: 8px;    /* Tamaño del scroll en horizontal */
    display: none;  /* Ocultar scroll */
}
/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
.container-login::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.container-login::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.container-login::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}
/* Ponemos un color de fondo y redondeamos las esquinas del track */
.container-login::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
}

/* Cambiamos el fondo cuando esté en active o hover */
.contcontainer-loginainer::-webkit-scrollbar-track:hover,
.contcontainer-loginainer::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
.mdc-snackbar__label, .mdc-snackbar__surface{
    background-color: #E79200;
}

.recoverypass-a {
    text-decoration: none;
    color: #535353;
    margin: 10px 0;
    display: grid;
    justify-content: space-around;
    align-content: space-evenly;
}
.mdc-ripple-upgraded.button-close-login-fab.mdc-fab.mdc-fab--extended{
    background-color: #E79200;    
    width: 155px;
    height: 100%;
    margin: 50px 10px 70px 50px; 
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background{
    border-color: #E99200;
    background-color: #E99200;
}
.mdc-checkbox__background{
    border-color: #E99200;
    background-color: #E99200;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background{
    border-color: #E99200;
    background-color: #E99200;
}