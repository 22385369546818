div .card-profile {
  text-align: center;
  max-width:  390px;
  background: #F0F0F0;
  color: #5C5C5C;
  display: flex; 
  flex-direction: column; 
  align-items: center;
  border-radius: 15px;
  margin-right: 20px;
  margin-top: 90px;
}

.iconArrow {
  font-size: 20px !important;
}
div .name-title{
  margin-top: 20px;
  margin-bottom: 0px;
  color:#FFA500;
}
div .button {
  margin: 5px;
  background: #FFA500 !important;
  color: white !important;
  min-width: 290px;
  min-height: 40px;
  margin-bottom: 20px;
}
.button .button-conten{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 125px;
}
.profile-points {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 100px;
}
.pointD {
  color: #1496F9;
}
.pointI {
  color: #E32256;
}
.pointS {
  color: #FFB126;
}
.pointC {
  color: #35CCDB;
}
.resDisc{
  display: flex;
  justify-content: center;
  align-items: center;
}
.type{
  display: flex;
  align-items: center;
  min-width: 210px ;
  min-height: 40px;
  font-size: medium;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #1496F9;
  color: #FFFFFF;
}
.leter{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  min-height: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #FFFFFF;
  font-size: large;
  color: black;
}

.type text{
  margin-left: 32px;
}