.conten-all-dialog{
  background-image: url('../../../res/images/background-details.png');
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;
  background-size: 45%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.conten-dialog {
  font-display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 30px;
  padding-top: 30px;
  padding-left:8%;
}

.conten-dialog h2{
  text-align: left;
  font-size: xx-large;
}
.conten-dialog h2 strong{
  color: #FFA500;
}
.conten-text{
  color: #1F1F1F;
  font-size: 12pt;
  width: 650px;
  padding: 5px;
  line-height: 1.4;
}
.subtitle-personaliti{
  margin: 0px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 21pt;
  font-family: Arial;
  -webkit-text-stroke: 1px #1F1F1F;
  color: transparent; 
}
.subtitle{
  margin: 0px;
  margin-top: 15px;
  font-weight: 800;
  font-size: 15pt;
  color: #FFA500;
}
.button-tutorial {
  background: black!important;
  color: white !important;
  min-width: 100px ;
  min-height: 30px;
  border-radius: 100px;
}
.button-ingresar {
  background: #FFA500!important;
  color: white !important;
  min-width: 200px ;
  min-height: 40px;
  border-radius: 100px;
}