.calendar, .calendarandevents {
  max-width: 100%;
}

.calendarandevents {
  text-align: center;
  width: 390px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.calendarandevents ol {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: 0;
  padding: 0;
  column-gap: 12px;
  row-gap: 12px;
}

.calendarandevents li {
  font-size: 1.5ch;
}

.first-day {
  grid-column-start: var(--first-day-start, 0);
}

.day-name {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFAE39;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 2px;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.day {
  background-color: #E4E4E4;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.month-title {
  background-color: #D9D9D9;
  border-radius: 5px;
  max-width: 385px;
  text-transform: uppercase;
  height: 40px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.today {
  background-color: #08F912;
}

@media (max-width: 500px) {
  .calendarandevents ol {
    column-gap: 0px;
    max-width: 350px;
    margin: auto;
  }

  .calendarandevents h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 330px;
  }

  .calendarandevents li {
    align-self: center;
    justify-self: center;
  }

  .calendarandevents {
    margin: 0 auto;
    display: inline-block;
  }

  .day-name, .day {
    width: 30px;
    height: 30px;
  }
}