.card-events {
  display: flex;
  margin: 15px 0;
}

.events-moreinfo a {
  display: inline-block;
  background-color: #FFAE39;
  height: 35px;
  width: 125px;
  border-radius: 50px;
  text-decoration: none;
  color: #000;
}

.events-moreinfo a:visited {
  color: #000
}

.events-moreinfo a div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.events-moreinfo {
  display: flex;
  justify-content: space-evenly;
  margin-right: 5px;
}

.events-moreinfo p {
  display: inline;
  margin: 0 3px;
  padding: 0;
}

.events-moreinfo p:first-child {
  padding: 0 15px;
}

.events-moreinfo p:last-child {
  background-color: #FFF;
  height: 84%;
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}


.moreinfo {
  background-color: #EDEDED;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
}

.events-info h4 {
  display: inline-block;
  border-radius: 50px;
  background-color: #F1F1F1;
  color: #868686;
  padding: 5px 10px;
  font-size: 16px;
  margin: 10px 0;
}

.events img {
  vertical-align: middle;
  width: 105px;
  height: 105px;
  border-radius: 50%;
}

.events-info h3 {
  margin: 0px;
  font-size: 20px;
}

.events-info {
  margin-left: 30px;
}

@media (max-width: 576px) {
  .card-events {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .events-info h3 {
    text-align: center;
  }

  .events-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}