.admin-menu {
  padding: 5px 5px 5px 20px;
  color: '#FFF';
}

.admin-menu li {
  margin: 15px 0;
}

.admin-menu li a{
  color: #FFF;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
}

.admin-menu li a:hover{
  color: #ACF;
}

.admin-menu li a:visited {
  color: '#FFF'
}

.log-out {
  min-width: 70%;
  margin: 0 auto;
  border: none;
  background-color: transparent;
  text-decoration: dashed;
  color: #FFF;
  font-size: 1.3em;
}

.log-out:hover {
  cursor: pointer;
  color: #ACF;
}