@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap);
:root {
    --system-font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Inter",
    sans-serif;
    --manrope-font: 'Manrope', var(--system-font);
    --default-font: 'Inter', var(--system-font);
    --highlight-font: 'Inter', var(--system-font), bold;
}


.use-manrope {
    font-family: 'Manrope', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Inter",
    sans-serif;
    font-family: var(--manrope-font);
}

img:not([alt]) {
    background-color: #f00;
}

body {
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Inter",
    sans-serif;
    font-family: var(--default-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    background: #fafafa;
    font-size: 14px;
}

h1 {
    font-family: var(--manrope-font);
    font-family: var(--mdc-typography-headline1-font-family, var(--manrope-font));
    font-size: 2.8rem;
    font-weight: 800;
}

h2 {
    font-family: var(--manrope-font);
    font-family: var(--mdc-typography-headline2-font-family, var(--manrope-font));
    font-size: 2.4rem;
    font-weight: 700;
}

h3 {
    font-family: var(--manrope-font);
    font-family: var(--mdc-typography-headline3-font-family, var(--manrope-font));
    font-size: 2.0rem;
    font-weight: 700;
}

h4 {
    font-family: var(--manrope-font);
    font-family: var(--mdc-typography-headline4-font-family, var(--manrope-font));
    font-size: 1.6rem;
    font-weight: 600;
}

h5 {
    font-family: var(--manrope-font);
    font-family: var(--mdc-typography-headline5-font-family, var(--manrope-font));
    font-size: 1.3rem;
}

h6 {
    font-family: var(--manrope-font);
    font-family: var(--mdc-typography-headline6-font-family, var(--manrope-font));
}

b {
    font-weight: 600;
}

p {
    font-size: 1rem;
}
#render-toolbar{
    display: block;
}
#menu-header{
    display: none;
}
@media only screen and (max-width: 1023px) {
    #render-toolbar{
        display: none;
    }
    #menu-header{
        display: block;
    }
}


.show {
    display: flex;
}

.hide {
    display: none;
}
.popup_dialog__1It9-{
    background-color: #E79200;
}
.popup_buttonPolicy__2IJqD{
    background-color: #82B428 !important;
    color: white !important;
}

.popup_titlePolicy__3v4-3{
    font-size: 18px;
    color: black;
    font-weight: bold;
}

.popup_subtitlePolicy__2Ljr_{
    font-size: 12px;
    color: black;
}

.popup_textPolicy__1FUxf {
    font-size: 10px;
    color: black;
  }
.send-enterprice {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #D9D9D9;
    font-family: 'Inter';
    font-style: normal;    
    font-size: 16px;   
    color: #000000;
}
.mdc-snackbar__label, .mdc-snackbar__surface{
    background-color: #E79200;
}
.sendLink-disable:hover{
    cursor: not-allowed !important;
  }

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background{
    background-color: #E79200 !important;
    border-color: #E79200 !important;
}

.container-login{  
    background-color: #fff;         
}
.img-bxlogo{
    height: 40%;
}
.selector-login{

    width: 215px;
    height: 25px; 
    font-size: 14px;
}
.home-bx{
    position: static;
    width: 100%; 
    height: 100vh; 
    margin: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(/static/media/BackgroundLogin.5432339f.svg);   
    top:0;  
}

.captcha {
    margin-left: 10%;
    margin-right: 10%;
}

button.is-medium.is-info.is-fullwidth.show {
    display: flex;
}

button.is-medium.is-info.is-fullwidth.hide {
    display: none;
}

.container.has-text-centered.box.login {
    max-width: 400px;
}

.show {
    display: block;
}

.show-captcha {
    display: block;
}

.hide {
    display: none;
}

.sign {
    text-align: center;
}

.login-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-login {
    display: flex;
    flex-direction: row;
    justify-content: space-around;    
    font-weight: 400;
    line-height: 17px;
    color: #535353;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    
}

.register {
    display: flex;
    width: 100%;
}
.button-close-login{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    
}
.container-login::-webkit-scrollbar {
    width: 30px;     /* Tamaño del scroll en vertical */
    height: 8px;    /* Tamaño del scroll en horizontal */
    display: none;  /* Ocultar scroll */
}
/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
.container-login::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.container-login::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.container-login::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}
/* Ponemos un color de fondo y redondeamos las esquinas del track */
.container-login::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
}

/* Cambiamos el fondo cuando esté en active o hover */
.contcontainer-loginainer::-webkit-scrollbar-track:hover,
.contcontainer-loginainer::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
.mdc-snackbar__label, .mdc-snackbar__surface{
    background-color: #E79200;
}

.recoverypass-a {
    text-decoration: none;
    color: #535353;
    margin: 10px 0;
    display: grid;
    justify-content: space-around;
    align-content: space-evenly;
}
.mdc-ripple-upgraded.button-close-login-fab.mdc-fab.mdc-fab--extended{
    background-color: #E79200;    
    width: 155px;
    height: 100%;
    margin: 50px 10px 70px 50px; 
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background{
    border-color: #E99200;
    background-color: #E99200;
}
.mdc-checkbox__background{
    border-color: #E99200;
    background-color: #E99200;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background{
    border-color: #E99200;
    background-color: #E99200;
}
:root {
    --mdc-typography-headline1-font-family: var(--titles-font);
    --mdc-typography-headline2-font-family: var(--titles-font);
    --mdc-typography-headline3-font-family: var(--titles-font);
    --mdc-typography-headline4-font-family: var(--titles-font);
    --mdc-typography-headline5-font-family: var(--titles-font);
    --mdc-typography-headline6-font-family: var(--titles-font);
    --mdc-typography-subtitle1-font-family: var(--titles-font);
    --mdc-typography-subtitle2-font-family: var(--titles-font);
    --mdc-typography-button-font-family: var(--titles-font);
    --mdc-typography-font-family: var(--default-font);
    --mdc-theme-error: #d43551;
    --toolbar-height: 72px;
    --content-top-padding: .8rem;
}

.mdc-typography--headline1,
.mdc-typography--headline2,
.mdc-typography--headline3,
.mdc-typography--headline4,
.mdc-typography--headline5,
.mdc-typography--headline6,
.mdc-drawer__title,
.mdc-top-app-bar__title,
.mdc-data-table__header-cell,
.mdc-dialog__title {
    font-family: var(--titles-font);
    font-weight: 700;
}

.mdc-drawer {
    top: 0;
}

.mdc-typography--subtitle1,
.mdc-typography--subtitle2,
.mdc-list-item__primary-text {
    font-family: var(--titles-font);
    font-weight: 500;
}

.mdc-typography--button,
.mdc-button,
.mdc-fab__label,
.mdc-tab {
    font-family: var(--titles-font);
    font-weight: 700;
    letter-spacing: 0.05715em;
}

.mdc-top-app-bar__row {
    height: 72px;
    height: var(--toolbar-height);
}

.mdc-top-app-bar--fixed-adjust {
    padding-top: calc(72px + .8rem);
    padding-top: calc(var(--toolbar-height) + var(--content-top-padding));
}

.mdc-top-app-bar__title {
    font-weight: 600;
    padding-left: 8px;
    margin-top: .2rem;
}

.mdc-top-app-bar,
.mdc-top-app-bar--fixed {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

.mdc-top-app-bar a {
    text-decoration: none !important;
    color: var(--mdc-theme-on-primary);
}

.mdc-body {
    min-height: calc(100vh - 72px - calc(.8rem * 3));
    min-height: calc(100vh - var(--toolbar-height) - calc(var(--content-top-padding, .8rem) * 3));
    padding: .8rem 1.6rem;
}

.mdc-snackbar {
    bottom: 1.2rem;
}

.mdc-snackbar--leading {
    left: 1.2rem;
}

.mdc-drawer__header {
    min-height: 72px;
    min-height: var(--toolbar-height);
    justify-content: center;
    align-items: center;
    display: flex;
}

.mdc-divider {
    height: 1px;
    background-color: rgba(0, 0, 0, .12);
}

.mdc-list-item__graphic.mdi {
    margin-top: .2rem;
    margin-right: 24px;
}

a.toolbar-logo {
    display: inline-block;
    line-height: 1;
}

a.toolbar-logo img {
    display: block;
    height: calc(72px - 1.6rem);
    height: calc(var(--toolbar-height) - 1.6rem);
    width: auto;
    max-width: 100%;
    margin-left: .8rem;
    padding-top: .4rem;
    padding-bottom: .4rem;
}

.mdc-text-field__input {
    font-family: var(--default-font);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
body .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label {
    color: #d43551;
    color: var(--mdc-theme-error, #b00020);
}

.mdc-fab .mdc-fab__label {
    font-size: 1rem;
}

.mdc-fab--extended .mdc-fab__icon {
    margin-left: -4px;
    margin-right: 8px;
}

.mdc-fab.positioned {
    position: fixed;
    bottom: 1.6rem;
    right: 1.6rem;
}

.mdc-drawer__content a {
    text-decoration: none;
}

button.mdc-button .mdc-button__label {
    margin-top: .2rem;
}

.mdc-text-field {
    width: 100%;
}

.button-full {
    width: 100%;
    height: 45px;
}

.button-medium {
    width: 50%;
    height: 45px;
}

.button-action {
    margin: 10px;
    height: 45px;
}

.profile {
    border-radius: 50%;
    width: 85% !important;
    height: 90% !important;
}

.full {
    width: 100%;
    height: 100%;
}

.profile-tool {
    padding: 0px;
    padding-top: 5px;
}

.mdc-list-item__graphic.mdi {
    font-size: 24px;
}

.tab-content {
    display: inline-flex;
}

.tab-text {
    padding: 15px;
}

.form-control {
    padding: 16px;
}

.fab-config {
    width: 40px;
    height: 40px;
    background-color: #1977ff;
}

.mdc-fab {
    color: white;
}

.nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0px;
}

.nav-options {
    margin-right: 20px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    padding: 10px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #535353;
    margin-right: 300px;
}

.toolbar-buttons {      
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
    grid-column-gap: 10px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    align-content: center;
    align-items: center;
}

.toolbar-login {
    width: 117px;
    height: 24px;
    background: #000000;
    border-radius: 50px;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
}

.toobar-singup {
    width: 117px;
    height: 24px;
    background: #FFAE39;
    border-radius: 50px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    
}
@font-face {
  font-family: Inter;
  src: url(/static/media/Inter.ba8d1544.ttf);
}

.carousel .control-dots .dot {
  opacity: 1;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: #FFAE39;
}

.main-label {
  position: absolute;
  max-width: 561px;
  left: 190px;
  top: 210px;
}

.main-label p {
  color: #FFFFFF;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 55px;
  font-weight: 700;
  text-align: left;
}

.main-label p span {
  color: #FFAE39;
}

.callAction {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 190px;
  top: 450px;
  width: 293px;
  height: 52px;
  background: #FFAE39;
  border-radius: 50px;
  text-decoration: none;
}

.callAction div {
  width: 100%;
  text-align: center;
  justify-content: center;
  margin: auto auto;
  font-size: 25px;
  color: #000;
  font-family: 'Inter';
  font-weight: 700;
}

.mainSlide {
  min-height: 750px;
  max-height: 90vh;
  object-fit: cover;
  object-position: 60%;
}

.hexaImg {
  position: absolute;
  bottom: 30px;
  right: 180px;
}

.hexaImg img {
  max-width: 650px;
}

@media (max-width: 960px) {

  .carousel {
    margin-top: 20px;
  }

  .main-label {
    left: 50%;
    top: 65px;
    width: 700px;
  }

  .main-label-bottom {
    top: 390px;
  }

  .__main-label {
    position: relative;
    left: -50%;
  }

  .main-label p {
    text-align: center;
    color: #FFF;
  }

  .main-label-bottom p {
    text-align: left;
    font-size: 36px;
  }

  .callAction {
    position: static;
    width: 250px;
  }

  .callAction div {
    color: #FFF;
  }


  .callAction_m {
    position: absolute;
    top: 620px;
    left: 50%;
  }

  .__callAction_m {
    position: relative;
    left: -50%;
  }

  .hexaImg {
    left: 50%;
    max-width: 500px;
    min-width: 340px;
    bottom: 50px;
  }

  .__hexaImg {
    position: relative;
    left: -50%;
  }

  .__hexaImg img {
    content:url(/static/media/mini.3405b900.png);
  }

  .mainSlide {
    height: 100vh;
    width: auto;
  }
}

@media (max-width: 576px) {
  .main-label p {
    font-size: 35px;
  }

  .main-label {
    position: absolute;
    max-width: 355px;
  }

  .main-label-bottom p {
    text-align: left;
    font-size: 32px;
    padding-left: 5px;
  }
}
.container-candidates {
    margin-top: 50px;
    width: auto;
    display: grid;
    align-items: center;
    background-color: #FFFFFF;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    color: #000000;


}

.container-image-candidates,
.container-image-candidates img {
    width: 100%;
}

.container-background-none-text {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;    
    color: #000000;

}

.container-background-none-text h2 {
    width: 50%;
    text-align: left;
    justify-content: center;
}

.container-background-none-text img {
    width: 50%;
}

.button-see-programs-candidates {
    margin-top: 50px;
    width: 117px;
    height: 24px;
    background: #FFAE39;
    border-radius: 50px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: black;
    font-weight: 700;
}
.calendar, .calendarandevents {
  max-width: 100%;
}

.calendarandevents {
  text-align: center;
  width: 390px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.calendarandevents ol {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: 0;
  padding: 0;
  grid-column-gap: 12px;
  -webkit-column-gap: 12px;
          column-gap: 12px;
  grid-row-gap: 12px;
  row-gap: 12px;
}

.calendarandevents li {
  font-size: 1.5ch;
}

.first-day {
  grid-column-start: 0;
  grid-column-start: var(--first-day-start, 0);
}

.day-name {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFAE39;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 2px;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.day {
  background-color: #E4E4E4;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.month-title {
  background-color: #D9D9D9;
  border-radius: 5px;
  max-width: 385px;
  text-transform: uppercase;
  height: 40px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.today {
  background-color: #08F912;
}

@media (max-width: 500px) {
  .calendarandevents ol {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
    max-width: 350px;
    margin: auto;
  }

  .calendarandevents h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 330px;
  }

  .calendarandevents li {
    align-self: center;
    justify-self: center;
  }

  .calendarandevents {
    margin: 0 auto;
    display: inline-block;
  }

  .day-name, .day {
    width: 30px;
    height: 30px;
  }
}
.card-events {
  display: flex;
  margin: 15px 0;
}

.events-moreinfo a {
  display: inline-block;
  background-color: #FFAE39;
  height: 35px;
  width: 125px;
  border-radius: 50px;
  text-decoration: none;
  color: #000;
}

.events-moreinfo a:visited {
  color: #000
}

.events-moreinfo a div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.events-moreinfo {
  display: flex;
  justify-content: space-evenly;
  margin-right: 5px;
}

.events-moreinfo p {
  display: inline;
  margin: 0 3px;
  padding: 0;
}

.events-moreinfo p:first-child {
  padding: 0 15px;
}

.events-moreinfo p:last-child {
  background-color: #FFF;
  height: 84%;
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}


.moreinfo {
  background-color: #EDEDED;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
}

.events-info h4 {
  display: inline-block;
  border-radius: 50px;
  background-color: #F1F1F1;
  color: #868686;
  padding: 5px 10px;
  font-size: 16px;
  margin: 10px 0;
}

.events img {
  vertical-align: middle;
  width: 105px;
  height: 105px;
  border-radius: 50%;
}

.events-info h3 {
  margin: 0px;
  font-size: 20px;
}

.events-info {
  margin-left: 30px;
}

@media (max-width: 576px) {
  .card-events {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .events-info h3 {
    text-align: center;
  }

  .events-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}
.separator {
  display: block;
  border: 2px solid #F6F6F6;
  width: 70%;
  background-color: orange;
  margin: 10px 0; 
}
.events-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin-top: 60px;
}

._calendar {
  background-color: #FAFAFA;
  width: 100%;
  display: flex;
  justify-content: center;
}

.calendar h2 {
  color: #535353;
}

.events h2 {
  color: #FFAE39;
}

@media (max-width: 960px) {
  .events-section {
    margin-top: 50px;
  }
}

.card-suscribe {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    background-color: #000000;
}

.container-newsletter {
    display: flex;
    flex-direction: column;
    background-color: #000000;
    margin-top: 0px;
}

.card-suscribe h2 {
    margin-bottom: 0;
    font-size: 20px;
}

.card-suscribe p {
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 0;
}

.newsletter-form {
    display: flex;
    grid-gap: 1rem;
    padding: 3em;
    margin: auto;
    flex-wrap: wrap;
}

.emailbox, .emailbox:focus-visible{
    width: 470px;
    height: 47.5px;
    margin-left: auto;
    background-color: #000000;
    border: 2px solid #FFAE39;
    border-radius: 60px 0px 0px 60px;
    border-right: none;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-left: 19px;
    outline: none;
    color: #FFFFFF;
}


.container-suscribe {
    display: flex;
    padding-left: 10px;
}

.container-suscribe button {
    display: flex;
    background: #FFAE39 !important;
    border-radius: 50px;
    width: 225px;
    height: 55px;
    border: none;
    position: relative;
    right: 23px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    text-transform: lowercase;
}


.container-suscribe button span:first-letter {
    text-transform: uppercase;
}
.mdc-snackbar__label, .mdc-snackbar__surface{
    background-color: #E79200;
}


@media (max-width: 900px) {
    .newsletter-form {
        width: auto;
        justify-content: center;
    }

    .emailbox {
        border-radius: 60px;
        width: auto;
        margin-bottom: 10px;
        border-right: 60px;       
        border: 2px solid #FFAE39;
        padding-left: 14px;
    }
    .emailbox:focus-visible{
        border-radius: 60px;
        width: auto;
        margin-bottom: 10px;
        border-right: 60px;       
        border: 2px solid #FFAE39;
        padding-left: 14px;
    }

    .container-suscribe {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .container-suscribe button {
        position: static;
    }

}
@media screen and (min-width: 900px) and (max-width: 1439px) {
    .newsletter-form {
        display: flex;
        grid-gap: 1rem;
        padding: 3em;
        margin: auto;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
    }
    .container-suscribe {
        display: flex;
        padding-left: 10px;
        flex-direction: row;
        max-width: 60%;
    }

}
.footer {
  margin: 0;
}

.first-footer {
  background-color: #535353;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0;
  min-height: 170px;
  flex-wrap: wrap
}

.first-footer h4 {
  margin: 0;
  font-size: 18px;
  color: #FFF;
  margin-bottom: 20px;
}

.first-footer img {
  background-color: transparent;
}

.first-footer a {
  display: block;
  color: #FFF;
  text-decoration: none;
  margin: 5px 0;
}

.second-footer {
  background-color: #393939;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 15px 0;
  height: 106px;
}

.second-footer p {
  margin: 0;
  color: #FFF;
}

.second-footer img {
  background-color: transparent;
}

.third-footer {
  background-color: #E79200;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #FFF;
}

.redes-sociales img {
  margin: 0 5px;
}

@media (max-width: 960px) {
  .first-footer {
    flex-direction: column;
  }
}
.container {
    background: #EFEFEF;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 150px auto 0;
    text-align: center;
    padding-bottom: 35px;
}

.title-section-graduates {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 45px;
    color: #535353;
}

.description-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #535353;
}

.card {
    background: #EFEFEF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card__title {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin: 0;
    color: #535353;
}

.text-title {
    margin: 0;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #535353;
}

.gallery {
    background: #EFEFEF;
    display: grid;
    grid-gap: 5rem;
    grid-gap: 5rem;
    gap: 5rem;
    grid-auto-flow: dense;

    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    padding: 1em;
}

.featured {
    grid-column: 1 /-1;
}
.programs-enterprise-img {
  background-color: #000 !important;
  max-width: 100%;
}

.programs-enterprise {
  position: relative;
}

.programs-enterprise h3 {
  color: #FFF;
  top: 0;
  left: 50%;
  right: 50%;
  font-size: 50px;
  display: inline-block;
  margin: 0;
  text-align: center;
}

.programs-enterprise .content {
  position: absolute;
  top: 35%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.programs-separator {
  border: 3px solid #FFAE39;
  width: 408px;
  height: 0px;
  margin: 25px 5px;
}

.programs-enterprise a {
  background-color: #FFAE39;
  display: inline-block;
  width: 250px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 35px 5px 5px 5px;
  border-radius: 50px;
  font-size: 25px;
  font-weight: 700;
}

.programs-enterprise a {
  text-decoration: none;
  cursor: pointer;
}

.programs-enterprise a:visited {
  color: #FFF;
}
header#menu-header{
    position: fixed;
    z-index: 10000;
    width: 100%;
    background-color: #ffffff;
    top: 0;
}
.container-menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 2em;

}
.container-menu figure{
    width: 10em;
    overflow: hidden;
    height: 50px;
    margin: 0;
}
.container-menu figure img{
    object-fit: contain;
    width: 100%;    
    height: 100%;
}
.span-icon{
    color: black;
    display: flex;
    grid-gap: 1em;
    gap: 1em;
    align-items: center;
    padding: 15px;
    cursor: pointer;
}
header#menu-header ul{
    display: block;
    position: fixed;
    width: 250px;
    max-width: 230px;
    list-style: none;
    height:100vh;
    padding: 2em;
    background-color: #ffffff;
    z-index: 1000;
    margin: 0;
    overflow: hidden;
}

.menu-statustrue{
    left: 0;
    transition: all .5s;

}
.menu-statusfalse{
    left: -100%;
    transition: left .5s ease-in-out;    
}
.menu-statustrue.overlay{
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1;
    transition: display .2s ease-in-out;
}
.menu-statusfalse.overlay{
   display: none;
}
header#menu-header ul.menu-statustrue li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    cursor: pointer;
}
.link-href{
    text-decoration: none;
}
header#menu-header ul.menu-statustrue li:active, header#menu-header ul.menu-statustrue li:hover {
    background-color: #e7920073 ;
    color: #000000;
    border-radius: 10px;

}
.partners-container {
    background: #FAFAFA;    
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    margin: auto;
    padding-top: 120px;
    width: 90%;
}

.text-partners {   
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    font-weight: 800;
    font-size: 25px;
    color: #535353;  
    text-align: center;
}

.carousel.carousel-slider{
    overflow: visible;
}
.carousel .control-dots{
    bottom: -30px;
}
.carousel .control-dots .dot {
    opacity: 1;
    background: #D9D9D9;
    margin-top: 100px;
    
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    margin-top: 1rem;
    background: #393939;
}


.container-programs-section-one {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: visible;
    background-color: #000000;
    margin-top: 100px;
}

.container-img-first {
    position: relative;
    display: inline-block;
    text-align: center;
    margin-top: -100px;
}

.container-img-second {
    position: relative;
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.containe-img-third {
    margin-bottom: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.container-img-fourth {
    margin-top: -250px;
    margin-left: auto;
    position: relative;
    margin-bottom: -100px;
}

.container-programs-section-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: visible;
    background-color: #FFAE39;
    margin-top: -91px;
    padding-top: 150px;
}

.text-complet {
    color: #FFFFFF;
    width: 80%;

}

.text-resalt {
    color: #FFAE39;
    font-weight: 700;
}

.button-start-now {
    margin-top: 50px;
    width: 117px;
    height: 35px;
    background: #FFAE39;
    border-radius: 50px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: black;
    font-weight: 700;
}

.link-style {
    text-decoration: none;
}

.text-complet-two {
    display: flex;
    color: #000000;
    width: 80%;
    text-align: right;
    flex-direction: column;
    font-weight: 400;
    font-family: 'Inter';
    font-style: normal;
    font-size: 30px;
    align-items: flex-end;
}

.button-see-programs {

    margin-top: 50px;
    width: 117px;
    height: 35px;
    background: #000000;
    border-radius: 50px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 700;
}

.container-img-first img,
.container-img-fourth img {
    max-width: 100vw;
}

@media (max-width: 481px) {
    .button-start-now {
        width: 100%;
        font-size: 20px;
    }

    .button-see-programs {
        width: 80%;
        font-size: 20px;
    }

    .container-img-first img {
        max-width: 100vw;
        margin-bottom: -100px;
        margin-top: 2rem;

    }

    .container-programs-section-one {
        flex-direction: column-reverse;
    }

    .container-img-first {
        margin-top: 0%;
    }

    .container-programs-section-two {
        margin-top: 0;
        flex-direction: column;
    }

    .container-img-fourth {
        margin-top: 2rem;
    }

    .container-programs-section-two {
        margin-top: 0;
    }

    .text-complet {
        padding-top: 2rem;
        text-align: center;
    }

    .link-style {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .text-complet-two {
        text-align: center;
        align-items: center;
    }
}
@media (max-width: 768px) {
    .button-start-now {
        width: 100%;
        font-size: 20px;
    }

    .button-see-programs {
        width: 80%;
        font-size: 20px;
    }

    .container-img-first img {
        max-width: 100vw;
        margin-bottom: -100px;
        margin-top: 2rem;

    }

    .container-programs-section-one {
        flex-direction: column-reverse;
    }

    .container-img-first {
        margin-top: 0%;
    }

    .container-programs-section-two {
        margin-top: 0;
        flex-direction: column;
    }

    .container-img-fourth {
        margin-top: 2rem;
    }

    .container-programs-section-two {
        margin-top: 0;
    }

    .text-complet {
        padding-top: 2rem;
        text-align: center;
    }

    .link-style {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .text-complet-two {
        text-align: center;
        align-items: center;
    }
}
@font-face {
  font-family: Inter;
  src: url(/static/media/Inter.ba8d1544.ttf);
}

.cardImg {
  position: relative;
  top: -30px;
  right: 20px;
  height: 120px;
}

.cardImg img {
  background-color: transparent;
  width: 140px;
}

.cardCourse {
  position: relative;
  border-radius: 10px;
  width: 420px;
  margin: 10px;
  padding: 5px 25px;
  height: 252px;
  margin-bottom: 30px;
  align-self: center;
  justify-self: center;
}

.cardCourse-blue {
  background: #6AC9FF;
}

.cardCourse-honey {
  background: #FFAE39;
}

.cardCourse-salmon {
  background: #FF6939;
}

.__cardCourseCart {
  position: absolute;
  display: inline-flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: 20px;
  background: rgba(255, 255, 255, 0.5);
}

.__cardCourseCart img {
  background: transparent;
  width: 18px;
  margin: auto;
}

ul {
  list-style: none;
  padding-left: 0;
}

.cardInformation li {
  margin-bottom: 10px;
  color: #FFF;
  font-size: 18px;
  align-content: center;
  display: flex;
}

.cardInformation li img {
  width: 20px;
  float: left;
  margin-right: 25px;
  padding: 0;
  margin: 0 10px 0 0;
  background-color: transparent;
}

.callAction-courses {
  display: flex;
  justify-content: center;
  width: 180px;
  height: 40px;
  background: #FFF;
  border-radius: 50px;
  text-decoration: none;
  border: 2px solid #000 ;
}

.callAction-courses div {
  width: 100%;
  text-align: center;
  justify-content: center;
  margin: auto auto;
  font-size: 16px;
  color: #000;
  font-family: 'Inter';
  font-weight: 700;
}

.__callAction li {
  list-style: none;
}

.__callAction img {
  background-color: transparent;
  width: 20px;
  margin-left: 5px;
}

.mainText {
  position: relative;
  top: 20px;
  width: 100%
}

.mainText h3 {
  font-size: 25px;
}

.__cardCourseRow1 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
}

.__cardCourseRow2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.__courses-section {
  display: flex;
  flex-direction: row;
  padding: 20px 10px;
  grid-row: 2;
  grid-column: 2 / 3;
  box-sizing: border-box;
}

.courses-section {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr ;
  grid-template-rows: auto auto;
}

.courses-section h2 {
  font-family: 'Inter';
  grid-column: 2 / 3;
  grid-row: 1;
  padding: 20px;
  color: #535353;
  font-weight: 800;
}

li, h3 {
  font-family: 'Inter';
}

.courses-section-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(470px, 1fr));
  grid-template-rows: auto auto;
  width: 100%;
}

@media (max-width: 576px) {
  .cardCourse {
    width: 302px;
    height: 298px;
  }

  .courses-section-grid {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
  .callAction-courses {
    width: 140px;
    height: 40px;
  }
}

.shop-title {
  font-size: 40px;
  max-width: 50%;
  color: '#242424';
  font-weight: 900;
}

.card-response {
  max-width: 600px;
  padding: 20px;
  background-color: #2A2A2ACC;
  position: relative;
  border-radius: 15px;
}

.section-response {
  display: flex;
  justify-content: center;
  color: #000;
}

.section-response__ {
  background-image: url(/static/media/backgroundpayments.58f639d0.png);
  background-color: #2A2A2A;
  background-size: cover;
  margin: 20px;
  border-radius: 10px;
}

.go-back {
  background-color: #FEA000;
  padding: 5px 15px;
  color: #FFF;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  border-radius: 15px;
  position: relative;
  top: 0px;
  left: 120px;
}

.go-back::before {
  content: ' ';
  background-color: #FEA000;
  position: absolute;
  width: 100px;
  height: 5px;
  display: block;
  left: -90px;
  top: 10px;
}

.select-price {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 75%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;

  border: 1px solid #555;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;

  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: right;
  text-align-last:center;
}

.select-price   option {
  white-space: normal;
  text-align-last:center;
  outline-color: var(--select-focus);
}

.espera-payments {
  position: absolute;
  right: 20px;
  top: 10px;
  max-width: 80px;
}

.wallet-payments {
  width: 180px;
  margin: auto;
  margin-top: 15px;
  display: block;
}

.gusano-payments {
  position: absolute;
  left: 20px;
  top: 20px;
  max-width: 80px;
}

.card-response h3 {
  margin: 0;
  color: #FFF;
}

.card-response p:first-of-type {
  color: #FEA000;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

.card-response p {
  margin: 10px 0;
  color: #FFF;
}

.card-response p span{
  margin: 10px 0;
  color: #ffc35d;
}

.admin-menu {
  padding: 5px 5px 5px 20px;
  color: '#FFF';
}

.admin-menu li {
  margin: 15px 0;
}

.admin-menu li a{
  color: #FFF;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
}

.admin-menu li a:hover{
  color: #ACF;
}

.admin-menu li a:visited {
  color: '#FFF'
}

.log-out {
  min-width: 70%;
  margin: 0 auto;
  border: none;
  background-color: transparent;
  -webkit-text-decoration: dashed;
          text-decoration: dashed;
  color: #FFF;
  font-size: 1.3em;
}

.log-out:hover {
  cursor: pointer;
  color: #ACF;
}
div .card-profile {
  text-align: center;
  max-width:  390px;
  background: #F0F0F0;
  color: #5C5C5C;
  display: flex; 
  flex-direction: column; 
  align-items: center;
  border-radius: 15px;
  margin-right: 20px;
  margin-top: 90px;
}

.iconArrow {
  font-size: 20px !important;
}
div .name-title{
  margin-top: 20px;
  margin-bottom: 0px;
  color:#FFA500;
}
div .button {
  margin: 5px;
  background: #FFA500 !important;
  color: white !important;
  min-width: 290px;
  min-height: 40px;
  margin-bottom: 20px;
}
.button .button-conten{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 125px;
    gap: 125px;
}
.profile-points {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 100px;
  -webkit-column-gap: 100px;
          column-gap: 100px;
}
.pointD {
  color: #1496F9;
}
.pointI {
  color: #E32256;
}
.pointS {
  color: #FFB126;
}
.pointC {
  color: #35CCDB;
}
.resDisc{
  display: flex;
  justify-content: center;
  align-items: center;
}
.type{
  display: flex;
  align-items: center;
  min-width: 210px ;
  min-height: 40px;
  font-size: medium;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #1496F9;
  color: #FFFFFF;
}
.leter{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  min-height: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #FFFFFF;
  font-size: large;
  color: black;
}

.type text{
  margin-left: 32px;
}
.conten-Details{
  background-image: url(/static/media/background-details.8e6bf1e7.png);
  background-color: #D9D9D9;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  max-width: 500px;
}
.Dialog-title {
  display: flex;
  align-items: center;
  text-align: left;
  border: 0px;
}
.dialog-conten-text{
  margin-left: 30px;
  margin-right: 30px;
  width: 420px;
  font-weight: 300;
  text-align: left;
  color: black;
}
.text-end{
  margin: 30px;
  font-weight: 270;
  text-align: left;
  color: black;
}
.close-x {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 30px !important;
  color: black;
}
.close-x:hover{
  color: #ffa80a;
}
.container-result-disc{
    background-color: #F0F0F0;
    width: 100%;
    margin-top: 93px;
    border-radius: 10px;
}
.container-time{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.container-time-one{
    padding: 15px;
}
.container-time-one h5{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #1B1B1B;
    margin: 0;
}
.container-time-one h4{ 
    color: #FFA500;
    margin: 0;
}
.container-reload{
    width: 100%;
    background-color:#BABABA;
    border-radius: 10px;
    padding: 5px;
}
.container-profile{
    display: flex;
    padding: 10px;
}


.container-result-profile h5{
    padding: 10px;
    
}

.linea {
    padding: 5px;
    border-bottom: 2px solid #D9D9D9;
}
.container-graphics{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 150px;
    gap: 150px;
}
.container-donut{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px
} 
.container-text-res{
    font-weight: 300;
    font-size: 10pt;
    padding: 25px;
    padding-top: 0;
    color: #1B1B1B;
}
.link  a {
  text-align: center;
  position: absolute;
  top: 62%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
}


.conten-Details{
  background-image: url(/static/media/background-details.8e6bf1e7.png);
  background-color: #D9D9D9;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  max-width: 500px;
}
.Dialog-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0px;
}
.dialog-conten-text{
  margin-left: 30px;
  margin-right: 30px;
  width: 420px;
  font-weight: 300;
  text-align: left;
  color: black;
}
.text-end{
  margin: 30px;
  margin-top: 30px;
  font-weight: 270;
  text-align: left;
  color: black;
}
.close-x {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  font-size: 30px !important;
  color: black;
}
.close-x:hover{
  color: #ffa80a;
}
.conten-button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
}
@media (max-width: 1000px) {
  .links {
    font-size: 50%;
  }
}
@media (max-width: 910px) {
  .links {
    font-size: 30%;
  }
}
@media (max-width: 870px) {
  .links {
    font-size: 0%;
  }
}
.conten{
  max-width: 410px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: normal !important;
}
.emoji {
  padding: 10px;
  width: 80px
}
.button-res {
  margin: 10px;
  background: #FFA500 !important;
  color: white !important;
  min-width: 270px ;
  min-height: 40px;
  text-shadow: 1px 1px 30px black;
}
.conten{
  max-width: 510px;
  min-height: 310px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: normal !important;
}

.container-dashboardDisc{
    margin-top: 50px;
}
.button-pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 190px;
    right: 80px;
    z-index: 1; 
    background: #FF6939 !important;
    border-radius: 100px; 
    color: #fff !important; 
}
.container-result{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 400px;
}
.container-routSuggest{
    display: flex;
    margin: 50px;
}
.container-louder{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 150px;
}
.wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: start;
}

.cardLogin {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #abc;
  max-height: 50%;
  align-items: center;
  margin-top: 100px;
  border-radius: 10px;
}

.cardLogin label {
  display: flex;
  flex-direction: column;
  margin: 8px;
  padding: 2px;
}

.cardLogin label input{
  height: 25px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #234;
}

.cardLogin label input:focus {
  outline: 1px solid #357;
}

.cardLogin h4 {
  margin: 0;
}

.cardLogin .wrong {
  color: #f44;
}
.recoveryPassContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #A3A3A3;
  height: 100vh;
}

.recoveryImg {
  position: absolute;
  top: -65px;
  padding: 25px;
  background-color: #FFF;
  border-radius: 50%;
}

.recoveryImg img {
  max-width: 80px;
}

.recoveryPass {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 512px;
  background-color: #FFF;
  border-radius: 25px;
}

.recoveryPass input {
  border-radius: 5px;
  background: #F9F9F9;
  border: 1px solid #D1D1D1;
  height: 42px;
  width: 270px;
  margin: 15px 5px;
}

.recoveryPass span {
  display: block;
}

.recoveryPass a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 148px;
  height: 37px;
  background-color: #535353;
  text-decoration: none;
  color: #FFF;
  position: relative;
  bottom: -20px;
}

.recoveryPass h5 {
  margin-top: 60px;
  font-size: 26px;
  font-weight: 800;
  text-align: center;
  color: #535353;
}

.recoveryPass span {
  font-size: 16px;
  color: #A3A3A3;
}

.invalidEmail{
  color: #FE0000;
  display: none;
}

.invalidEmail-show {
  display: inline;
}

.recoveryPass a:hover {
  cursor: pointer;
}

.sendLink-disable:hover{
  cursor: not-allowed !important;
}


/*dfghjk*/

#newPassOne:focus-within:after {
  position: absolute;
  content: 'La contraseña debe tener por lo menos: Diez dígitos, una mayúscula, una minúscula, un número y un carácter especial: $.:;,@$!%*?&#=';
  background-color: rgba(0, 0, 0, 0.8);
  color: #FFF;
  padding: 10px;
  left: 0;
  top: 110px;
}

.ivalidInput {
  border: 1px solid red !important;
}
.carddrag {
  background-color: #E79200;
  color: #FFFFFF;
  min-width: 165px;
  margin: 5px;
  padding: 5px;
  border-radius: 50px;
  font-weight: bold;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.carddrag:hover {
  cursor: move;
  /* fallback: no `url()` support or images disabled */
  /* Chrome 1-21, Safari 4+ */
  /* Firefox 1.5-26 */
  cursor: grab;
}
.spot {
  margin: 5px;
  min-width: 175px;
  height: 50px;
  border-radius: 50px;
  border: 2px dotted #E79200;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-weight: bold;
}

.option-drop {
  background-color: #2D2D2D;
  display: inline-flex;
  flex-direction: column;
  margin: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 20px 5px 10px;
  color: #FFF;
  font-weight: bold;
  border-radius: 25px;
  min-width: 245px;
  min-height: 230px;
}

.option-drop img:last-child {
  margin-top: 10px;
}

.option-description {
  max-width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 0;
  margin-top: 10px;
  -webkit-animation: aparecer 1.5s 0s;
          animation: aparecer 1.5s 0s;
}

.option-drop button {
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
}

.option-drop button:hover {
  cursor: pointer;
}

.math-card {
  padding: 0;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

@-webkit-keyframes aparecer {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

}

@keyframes aparecer {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

}

.question-disc {
  display: flex;
  justify-content: center;
  align-items: center;
}

._question-disc {
  margin-top: 40px;
  display: inline-flex;
  flex-direction: column;
  background-color: #D9D9D9;
  border-radius: 25px;
  padding: 30px 30px 40px;
}

._question-disc p { 
  color: #E79200;
}
._question-disc #text-dragAndDrop{
  color: #2D2D2D;
}

.conten{
  max-width: 410px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: normal !important;
}
.emoji {
  padding: 10px;
  width: 80px
}
.button-res {
  margin: 10px;
  background: #FFA500 !important;
  color: white !important;
  min-width: 270px ;
  min-height: 40px;
  text-shadow: 1px 1px 30px black;
}
.conten-all-dialog{
  background-image: url(/static/media/background-details.8e6bf1e7.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;
  background-size: 45%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.conten-dialog-tutorial {
  background-color: #1F1F1F;
  font-display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 30px;
  padding-top: 30px;
  padding-left:8%;
}

.conten-dialog h2{
  text-align: left;
  font-size: xx-large;
}
.conten-text-tutorial{
  color: #ffffff;
  font-size: 12pt;
  width: 600px;
  padding: 5px;
  line-height: 1.4;
}
.ul-text-tutorial {
  color: #ffffff;
  font-size: 12pt;
  width: 660px;
  line-height: 1.6;
  padding-left: 15px;
}
.subtitle-personaliti{
  margin: 0px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 21pt;
  font-family: Arial;
  -webkit-text-stroke: 1px #1F1F1F;
  color: transparent; 
}
.subtitle{
  margin: 0px;
  margin-top: 15px;
  font-weight: 800;
  font-size: 15pt;
  color: #FFA500;
}

.conten{
  max-width: 410px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: normal !important;
}
.emoji {
  padding: 10px;
  width: 80px
}
.button-res {
  margin: 10px;
  background: #FFA500 !important;
  color: white !important;
  min-width: 270px ;
  min-height: 40px;
  text-shadow: 1px 1px 30px black;
}
.conten text{
  font-size: medium;
}
.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-completed {

  color: #E79200; /* Cambiar el color step completed */
}
.container-disc-test{
    margin-top: 100px;
    background: linear-gradient(180deg, #FFFFFF 0%, #ffffff 100%);
    border-radius: 45px;
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 20px;
}

._question-disc{
  display: flex;
  margin-top: 10px;
  padding: 30px;
}
@media screen and (min-width: 1390px) {
  ._question-disc{
  margin-left: 3vw;
  }
}
.buttons-container{
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 10px;
}
.button-back-disc{
    margin: 18px;
    background: #2D2D2D;
    border-radius: 40px;
    width: 180px;
    height: 45px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
}
.button-next-disc{
    margin: 18px;
    background: #C8C8C8;
    border-radius: 40px;
    width: 180px;
    height: 45px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #B3B3B3;
}
.active {
    margin: 18px;
    background: #2D2D2D;
    border-radius: 40px;
    width: 206px;
    height: 53px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
  }
@media screen and (max-width: 767px) {
    .buttons-container{
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 20px;
      margin-top: 40px;
    }
  }
.split-screen {
    display: flex;
    height: 750px;
    background-image: url(/static/media/backgroundHomeDisc.94f9689b.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
  
.left, .right {
    flex: 1 1;
}

.right img {
    max-width: 55%;
    margin-top: 100px;
    margin-left: 80px;
}

.text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    color: #5C5C5C;
    font-size: 40px;
    width: 100%;
    margin-left: 176px;
    justify-content: center;
    margin-top: 80px;
    
}

.text-y {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    color: #E79200;
    font-size: 50px;
    width: 100%;
    margin-left: 176px;
    justify-content: center;
}

.container-text{
    margin-left: 176px;
    font-family: 'Inter';
    font-style: normal;
    color: #5C5C5C;
    font-size: 14px;
    width: 100%;
    justify-content: center;
}

.container-button{
    margin-left: 176px;
    margin-top: 10px;
}


.conten-all-dialog{
  background-image: url(/static/media/background-details.8e6bf1e7.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;
  background-size: 45%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.conten-dialog {
  font-display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 30px;
  padding-top: 30px;
  padding-left:8%;
}

.conten-dialog h2{
  text-align: left;
  font-size: xx-large;
}
.conten-dialog h2 strong{
  color: #FFA500;
}
.conten-text{
  color: #1F1F1F;
  font-size: 12pt;
  width: 650px;
  padding: 5px;
  line-height: 1.4;
}
.subtitle-personaliti{
  margin: 0px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 21pt;
  font-family: Arial;
  -webkit-text-stroke: 1px #1F1F1F;
  color: transparent; 
}
.subtitle{
  margin: 0px;
  margin-top: 15px;
  font-weight: 800;
  font-size: 15pt;
  color: #FFA500;
}
.button-tutorial {
  background: black!important;
  color: white !important;
  min-width: 100px ;
  min-height: 30px;
  border-radius: 100px;
}
.button-ingresar {
  background: #FFA500!important;
  color: white !important;
  min-width: 200px ;
  min-height: 40px;
  border-radius: 100px;
}
.conten-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.background-info{
  background-image: url(/static/media/background-details.8e6bf1e7.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;
  background-size: 45%;
}


.conten-dialog-title {
  font-display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 30px;
  padding-top: 30px;
  padding-left: 8%;

}

.conten-dialog-title h2{

  font-weight: 900;
  font-size: 30pt;
  font-family: Arial;
  -webkit-text-stroke: 2px #FFA500;
  color: transparent; 
  text-align: left;
}

.conten-dialog-text{
  text-align: left;
  color: #1F1F1F;
  font-size: 12pt;
  width: 700px;
  padding: 5px;
  line-height: 1.4;
}

.button-home-ingresar {
  background: #E79200!important;
  color: white !important;
  min-width: 100px ;
  min-height: 30px;
  border-radius: 100px;
}
.button-ingresar {
  background: #FFA500!important;
  color: white !important;
  min-width: 200px ;
  min-height: 40px;
  border-radius: 100px;
}
