.partners-container {
    background: #FAFAFA;    
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: auto;
    padding-top: 120px;
    width: 90%;
}

.text-partners {   
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    font-weight: 800;
    font-size: 25px;
    color: #535353;  
    text-align: center;
}

.carousel.carousel-slider{
    overflow: visible;
}
.carousel .control-dots{
    bottom: -30px;
}
.carousel .control-dots .dot {
    opacity: 1;
    background: #D9D9D9;
    margin-top: 100px;
    
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    margin-top: 1rem;
    background: #393939;
}

