.split-screen {
    display: flex;
    height: 750px;
    background-image: url('../../res/images/backgroundHomeDisc.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
  
.left, .right {
    flex: 1;
}

.right img {
    max-width: 55%;
    margin-top: 100px;
    margin-left: 80px;
}

.text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    color: #5C5C5C;
    font-size: 40px;
    width: 100%;
    margin-left: 176px;
    justify-content: center;
    margin-top: 80px;
    
}

.text-y {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    color: #E79200;
    font-size: 50px;
    width: 100%;
    margin-left: 176px;
    justify-content: center;
}

.container-text{
    margin-left: 176px;
    font-family: 'Inter';
    font-style: normal;
    color: #5C5C5C;
    font-size: 14px;
    width: 100%;
    justify-content: center;
}

.container-button{
    margin-left: 176px;
    margin-top: 10px;
}

