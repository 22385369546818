.conten{
  max-width: 510px;
  min-height: 310px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: normal !important;
}
