.dialog{
    background-color: #E79200;
}
.buttonPolicy{
    background-color: #82B428 !important;
    color: white !important;
}

.titlePolicy{
    font-size: 18px;
    color: black;
    font-weight: bold;
}

.subtitlePolicy{
    font-size: 12px;
    color: black;
}

.textPolicy {
    font-size: 10px;
    color: black;
  }