.container-programs-section-one {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: visible;
    background-color: #000000;
    margin-top: 100px;
}

.container-img-first {
    position: relative;
    display: inline-block;
    text-align: center;
    margin-top: -100px;
}

.container-img-second {
    position: relative;
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.containe-img-third {
    margin-bottom: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.container-img-fourth {
    margin-top: -250px;
    margin-left: auto;
    position: relative;
    margin-bottom: -100px;
}

.container-programs-section-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: visible;
    background-color: #FFAE39;
    margin-top: -91px;
    padding-top: 150px;
}

.text-complet {
    color: #FFFFFF;
    width: 80%;

}

.text-resalt {
    color: #FFAE39;
    font-weight: 700;
}

.button-start-now {
    margin-top: 50px;
    width: 117px;
    height: 35px;
    background: #FFAE39;
    border-radius: 50px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: black;
    font-weight: 700;
}

.link-style {
    text-decoration: none;
}

.text-complet-two {
    display: flex;
    color: #000000;
    width: 80%;
    text-align: right;
    flex-direction: column;
    font-weight: 400;
    font-family: 'Inter';
    font-style: normal;
    font-size: 30px;
    align-items: flex-end;
}

.button-see-programs {

    margin-top: 50px;
    width: 117px;
    height: 35px;
    background: #000000;
    border-radius: 50px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 700;
}

.container-img-first img,
.container-img-fourth img {
    max-width: 100vw;
}

@media (max-width: 481px) {
    .button-start-now {
        width: 100%;
        font-size: 20px;
    }

    .button-see-programs {
        width: 80%;
        font-size: 20px;
    }

    .container-img-first img {
        max-width: 100vw;
        margin-bottom: -100px;
        margin-top: 2rem;

    }

    .container-programs-section-one {
        flex-direction: column-reverse;
    }

    .container-img-first {
        margin-top: 0%;
    }

    .container-programs-section-two {
        margin-top: 0;
        flex-direction: column;
    }

    .container-img-fourth {
        margin-top: 2rem;
    }

    .container-programs-section-two {
        margin-top: 0;
    }

    .text-complet {
        padding-top: 2rem;
        text-align: center;
    }

    .link-style {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .text-complet-two {
        text-align: center;
        align-items: center;
    }
}
@media (max-width: 768px) {
    .button-start-now {
        width: 100%;
        font-size: 20px;
    }

    .button-see-programs {
        width: 80%;
        font-size: 20px;
    }

    .container-img-first img {
        max-width: 100vw;
        margin-bottom: -100px;
        margin-top: 2rem;

    }

    .container-programs-section-one {
        flex-direction: column-reverse;
    }

    .container-img-first {
        margin-top: 0%;
    }

    .container-programs-section-two {
        margin-top: 0;
        flex-direction: column;
    }

    .container-img-fourth {
        margin-top: 2rem;
    }

    .container-programs-section-two {
        margin-top: 0;
    }

    .text-complet {
        padding-top: 2rem;
        text-align: center;
    }

    .link-style {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .text-complet-two {
        text-align: center;
        align-items: center;
    }
}