.shop-title {
  font-size: 40px;
  max-width: 50%;
  color: '#242424';
  font-weight: 900;
}

.card-response {
  max-width: 600px;
  padding: 20px;
  background-color: #2A2A2ACC;
  position: relative;
  border-radius: 15px;
}

.section-response {
  display: flex;
  justify-content: center;
  color: #000;
}

.section-response__ {
  background-image: url('./../../res/images/backgroundpayments.png');
  background-color: #2A2A2A;
  background-size: cover;
  margin: 20px;
  border-radius: 10px;
}

.go-back {
  background-color: #FEA000;
  padding: 5px 15px;
  color: #FFF;
  text-decoration-line: none;
  border-radius: 15px;
  position: relative;
  top: 0px;
  left: 120px;
}

.go-back::before {
  content: ' ';
  background-color: #FEA000;
  position: absolute;
  width: 100px;
  height: 5px;
  display: block;
  left: -90px;
  top: 10px;
}

.select-price {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 75%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;

  border: 1px solid #555;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;

  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: right;
  text-align-last:center;
}

.select-price   option {
  white-space: normal;
  text-align-last:center;
  outline-color: var(--select-focus);
}

.espera-payments {
  position: absolute;
  right: 20px;
  top: 10px;
  max-width: 80px;
}

.wallet-payments {
  width: 180px;
  margin: auto;
  margin-top: 15px;
  display: block;
}

.gusano-payments {
  position: absolute;
  left: 20px;
  top: 20px;
  max-width: 80px;
}

.card-response h3 {
  margin: 0;
  color: #FFF;
}

.card-response p:first-of-type {
  color: #FEA000;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

.card-response p {
  margin: 10px 0;
  color: #FFF;
}

.card-response p span{
  margin: 10px 0;
  color: #ffc35d;
}
