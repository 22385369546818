.container-result-disc{
    background-color: #F0F0F0;
    width: 100%;
    margin-top: 93px;
    border-radius: 10px;
}
.container-time{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.container-time-one{
    padding: 15px;
}
.container-time-one h5{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #1B1B1B;
    margin: 0;
}
.container-time-one h4{ 
    color: #FFA500;
    margin: 0;
}
.container-reload{
    width: 100%;
    background-color:#BABABA;
    border-radius: 10px;
    padding: 5px;
}
.container-profile{
    display: flex;
    padding: 10px;
}


.container-result-profile h5{
    padding: 10px;
    
}

.linea {
    padding: 5px;
    border-bottom: 2px solid #D9D9D9;
}
.container-graphics{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
}
.container-donut{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px
} 
.container-text-res{
    font-weight: 300;
    font-size: 10pt;
    padding: 25px;
    padding-top: 0;
    color: #1B1B1B;
}