.wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: start;
}

.cardLogin {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #abc;
  max-height: 50%;
  align-items: center;
  margin-top: 100px;
  border-radius: 10px;
}

.cardLogin label {
  display: flex;
  flex-direction: column;
  margin: 8px;
  padding: 2px;
}

.cardLogin label input{
  height: 25px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #234;
}

.cardLogin label input:focus {
  outline: 1px solid #357;
}

.cardLogin h4 {
  margin: 0;
}

.cardLogin .wrong {
  color: #f44;
}