.link  a {
  text-align: center;
  position: absolute;
  top: 62%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
}

