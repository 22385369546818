.programs-enterprise-img {
  background-color: #000 !important;
  max-width: 100%;
}

.programs-enterprise {
  position: relative;
}

.programs-enterprise h3 {
  color: #FFF;
  top: 0;
  left: 50%;
  right: 50%;
  font-size: 50px;
  display: inline-block;
  margin: 0;
  text-align: center;
}

.programs-enterprise .content {
  position: absolute;
  top: 35%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.programs-separator {
  border: 3px solid #FFAE39;
  width: 408px;
  height: 0px;
  margin: 25px 5px;
}

.programs-enterprise a {
  background-color: #FFAE39;
  display: inline-block;
  width: 250px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 35px 5px 5px 5px;
  border-radius: 50px;
  font-size: 25px;
  font-weight: 700;
}

.programs-enterprise a {
  text-decoration: none;
  cursor: pointer;
}

.programs-enterprise a:visited {
  color: #FFF;
}