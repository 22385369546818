.events-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin-top: 60px;
}

._calendar {
  background-color: #FAFAFA;
  width: 100%;
  display: flex;
  justify-content: center;
}

.calendar h2 {
  color: #535353;
}

.events h2 {
  color: #FFAE39;
}

@media (max-width: 960px) {
  .events-section {
    margin-top: 50px;
  }
}
