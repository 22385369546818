.recoveryPassContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #A3A3A3;
  height: 100vh;
}

.recoveryImg {
  position: absolute;
  top: -65px;
  padding: 25px;
  background-color: #FFF;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.recoveryImg img {
  max-width: 80px;
}

.recoveryPass {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 512px;
  background-color: #FFF;
  border-radius: 25px;
}

.recoveryPass input {
  border-radius: 5px;
  background: #F9F9F9;
  border: 1px solid #D1D1D1;
  height: 42px;
  width: 270px;
  margin: 15px 5px;
}

.recoveryPass span {
  display: block;
}

.recoveryPass a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 148px;
  height: 37px;
  background-color: #535353;
  text-decoration: none;
  color: #FFF;
  position: relative;
  bottom: -20px;
}

.recoveryPass h5 {
  margin-top: 60px;
  font-size: 26px;
  font-weight: 800;
  text-align: center;
  color: #535353;
}

.recoveryPass span {
  font-size: 16px;
  color: #A3A3A3;
}

.invalidEmail{
  color: #FE0000;
  display: none;
}

.invalidEmail-show {
  display: inline;
}

.recoveryPass a:hover {
  cursor: pointer;
}

.sendLink-disable:hover{
  cursor: not-allowed !important;
}


/*dfghjk*/

#newPassOne:focus-within:after {
  position: absolute;
  content: 'La contraseña debe tener por lo menos: Diez dígitos, una mayúscula, una minúscula, un número y un carácter especial: $.:;,@$!%*?&#=';
  background-color: rgba(0, 0, 0, 0.8);
  color: #FFF;
  padding: 10px;
  left: 0;
  top: 110px;
}

.ivalidInput {
  border: 1px solid red !important;
}